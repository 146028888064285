var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Personal Data Siswa ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NISN","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"number","state":errors.length > 0 ? false : null,"placeholder":"NISN"},model:{value:(_vm.dataStudent.nisn),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nisn", $$v)},expression:"dataStudent.nisn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Nama"},model:{value:(_vm.dataStudent.name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "name", $$v)},expression:"dataStudent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"birth_place"}},[_c('validation-provider',{attrs:{"name":"birth_place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_place","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Tempat Lahir"},model:{value:(_vm.dataStudent.birth_place),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth_place", $$v)},expression:"dataStudent.birth_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"birth_date"}},[_c('validation-provider',{attrs:{"name":"birth_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Lahir"},model:{value:(_vm.dataStudent.birth_date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth_date", $$v)},expression:"dataStudent.birth_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"gender","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"gender","reduce":function (status) { return status.value; },"placeholder":"Pilih Jenis Kelamin","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataGender,"label":"text"},model:{value:(_vm.dataStudent.gender),callback:function ($$v) {_vm.$set(_vm.dataStudent, "gender", $$v)},expression:"dataStudent.gender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Agama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Agama","label-for":"religion","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"religion","reduce":function (religion) { return religion.value; },"placeholder":"Pilih Agama","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataReligion,"label":"text"},model:{value:(_vm.dataStudent.religion),callback:function ($$v) {_vm.$set(_vm.dataStudent, "religion", $$v)},expression:"dataStudent.religion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Kewarganegaraan","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nationality","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Kebangsaan"},model:{value:(_vm.dataStudent.nationality),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nationality", $$v)},expression:"dataStudent.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Anak ke (dikeluarga)","label-for":"child_number"}},[_c('validation-provider',{attrs:{"name":"Anak ke (dikeluarga)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_number","type":"number","state":errors.length > 0 ? false : null,"placeholder":"1"},model:{value:(_vm.dataStudent.child_number),callback:function ($$v) {_vm.$set(_vm.dataStudent, "child_number", $$v)},expression:"dataStudent.child_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jumlah Sudara Kandung","label-for":"number_of_sibling"}},[_c('validation-provider',{attrs:{"name":"Jumlah Sudara Kandung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number_of_sibling","type":"number","state":errors.length > 0 ? false : null,"placeholder":"3"},model:{value:(_vm.dataStudent.number_of_sibling),callback:function ($$v) {_vm.$set(_vm.dataStudent, "number_of_sibling", $$v)},expression:"dataStudent.number_of_sibling"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Bahasa Sehari-hari","label-for":"every_day_language"}},[_c('validation-provider',{attrs:{"name":"Bahasa Sehari-hari"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"every_day_language","state":errors.length > 0 ? false : null,"placeholder":"Bahasa Indonesia"},model:{value:(_vm.dataStudent.every_day_language),callback:function ($$v) {_vm.$set(_vm.dataStudent, "every_day_language", $$v)},expression:"dataStudent.every_day_language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provinsi","label-for":"province","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"province","placeholder":"Pilih Provinsi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataProvinces,"label":"name"},model:{value:(_vm.locations.province),callback:function ($$v) {_vm.$set(_vm.locations, "province", $$v)},expression:"locations.province"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Kabupaten/Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kabupaten/Kota","label-for":"city","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"city","placeholder":"Pilih Kabupaten/Kota","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterCities,"label":"name"},model:{value:(_vm.locations.city),callback:function ($$v) {_vm.$set(_vm.locations, "city", $$v)},expression:"locations.city"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kecamatan","label-for":"distric","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"distric","placeholder":"Pilih Kecamatan","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterDistrics,"label":"name"},model:{value:(_vm.locations.distric),callback:function ($$v) {_vm.$set(_vm.locations, "distric", $$v)},expression:"locations.distric"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Desa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Desa","label-for":"subdistrict","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"subdistrict","placeholder":"Pilih Desa","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.filterSubDistrics,"label":"name"},model:{value:(_vm.locations.subdistrict),callback:function ($$v) {_vm.$set(_vm.locations, "subdistrict", $$v)},expression:"locations.subdistrict"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Alamat","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Jl. Kaliurang Km. 7 No.B5"},model:{value:(_vm.dataStudent.address),callback:function ($$v) {_vm.$set(_vm.dataStudent, "address", $$v)},expression:"dataStudent.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"RW","label-for":"rw"}},[_c('validation-provider',{attrs:{"name":"rw","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rw","state":errors.length > 0 ? false : null,"placeholder":"11"},model:{value:(_vm.dataStudent.rw),callback:function ($$v) {_vm.$set(_vm.dataStudent, "rw", $$v)},expression:"dataStudent.rw"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"RT","label-for":"rt"}},[_c('validation-provider',{attrs:{"name":"rt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rt","state":errors.length > 0 ? false : null,"placeholder":"21"},model:{value:(_vm.dataStudent.rt),callback:function ($$v) {_vm.$set(_vm.dataStudent, "rt", $$v)},expression:"dataStudent.rt"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jarak Ke Sekolah","label-for":"distance"}},[_c('validation-provider',{attrs:{"name":"Jarak Ke Sekolah"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"distance","type":"number","state":errors.length > 0 ? false : null,"placeholder":"14"},model:{value:(_vm.dataStudent.distance),callback:function ($$v) {_vm.$set(_vm.dataStudent, "distance", $$v)},expression:"dataStudent.distance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"blood_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Golongan Darah","label-for":"blood_type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"blood_type","reduce":function (blood_type) { return blood_type.id; },"placeholder":"Pilih Golongan Darah","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listBloods,"label":"blood_type_desc"},model:{value:(_vm.dataStudent.blood_type),callback:function ($$v) {_vm.$set(_vm.dataStudent, "blood_type", $$v)},expression:"dataStudent.blood_type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Riwayat Penyakit","label-for":"illness_history"}},[_c('validation-provider',{attrs:{"name":"Riwayat Penyakit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"illness_history","state":errors.length > 0 ? false : null,"placeholder":"Asma"},model:{value:(_vm.dataStudent.illness_history),callback:function ($$v) {_vm.$set(_vm.dataStudent, "illness_history", $$v)},expression:"dataStudent.illness_history"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Kemampuan Membaca Al-Qur'an","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kemampuan Membaca Al-Qur'an","label-for":"reading_ability_quran","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"reading_ability_quran","reduce":function (read) { return read.value; },"placeholder":"Pilih Kemampuan Membaca Al-Qur'an","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataReadingQuran,"label":"text"},model:{value:(_vm.dataStudent.reading_ability_quran),callback:function ($$v) {_vm.$set(_vm.dataStudent, "reading_ability_quran", $$v)},expression:"dataStudent.reading_ability_quran"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"No. Handphone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"No. Handphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"placeholder":"08xxxxxxxxx"},model:{value:(_vm.dataStudent.phone),callback:function ($$v) {_vm.$set(_vm.dataStudent, "phone", $$v)},expression:"dataStudent.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"admin@admin.com"},model:{value:(_vm.dataStudent.phone_2),callback:function ($$v) {_vm.$set(_vm.dataStudent, "phone_2", $$v)},expression:"dataStudent.phone_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Ayah","label-for":"father_name"}},[_c('validation-provider',{attrs:{"name":"father_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"father_name","state":errors.length > 0 ? false : null,"placeholder":"Andi"},model:{value:(_vm.dataStudent.father_name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "father_name", $$v)},expression:"dataStudent.father_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Ibu","label-for":"mother_name"}},[_c('validation-provider',{attrs:{"name":"mother_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mother_name","state":errors.length > 0 ? false : null,"placeholder":"Susi"},model:{value:(_vm.dataStudent.mother_name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "mother_name", $$v)},expression:"dataStudent.mother_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Anak ke (1 Lembaga)","label-for":"child_number_institution"}},[_c('validation-provider',{attrs:{"name":"Anak ke (1 Lembaga)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_number_institution","type":"number","state":errors.length > 0 ? false : null,"placeholder":"1"},model:{value:(_vm.dataStudent.child_number_institution),callback:function ($$v) {_vm.$set(_vm.dataStudent, "child_number_institution", $$v)},expression:"dataStudent.child_number_institution"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"father_education_level_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pendidikan Terakhir Ayah","label-for":"father_education_level_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"father_education_level_id","reduce":function (father_education_level_id) { return father_education_level_id.id; },"placeholder":"Pendidikan Terakhir Ayah","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listEducations,"label":"level_education_desc"},model:{value:(_vm.dataStudent.father_education_level_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "father_education_level_id", $$v)},expression:"dataStudent.father_education_level_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"mother_education_level_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pendidikan Terakhir Ibu","label-for":"mother_education_level_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"mother_education_level_id","reduce":function (mother_education_level_id) { return mother_education_level_id.id; },"placeholder":"Pendidikan Terakhir Ibu","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listEducations,"label":"level_education_desc"},model:{value:(_vm.dataStudent.mother_education_level_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "mother_education_level_id", $$v)},expression:"dataStudent.mother_education_level_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Mutation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mutation","label-for":"mutation","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"mutation","reduce":function (mutation) { return mutation.value; },"placeholder":"Pilih Mutation","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataMutations,"label":"text"},model:{value:(_vm.dataStudent.mutation),callback:function ($$v) {_vm.$set(_vm.dataStudent, "mutation", $$v)},expression:"dataStudent.mutation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"accepted_in_class","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"DiTerima di Kelas","label-for":"accepted_in_class","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"accepted_in_class","reduce":function (accepted_in_class) { return accepted_in_class.id; },"placeholder":"DiTerima di Kelas","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listClass,"label":"description"},model:{value:(_vm.dataStudent.accepted_in_class),callback:function ($$v) {_vm.$set(_vm.dataStudent, "accepted_in_class", $$v)},expression:"dataStudent.accepted_in_class"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"NIK (No KTP)","label-for":"illness_history"}},[_c('validation-provider',{attrs:{"name":"nik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nik","state":errors.length > 0 ? false : null,"placeholder":"386..."},model:{value:(_vm.dataStudent.nik),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nik", $$v)},expression:"dataStudent.nik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }